import { get, post, deletes } from 'common/request'

const permission = {
  addPermission: (params) => post('/permission/add', params),
  editPermission: (params) => post('/permission/update', params),
  delPermission: (params) => deletes('/permission/del', params),
  deleteBatch: (params) => deletes('/permission/deleteBatch', params),
  getPermissionList: (params) => get('/permission/getSystemMenuList', params),
  getMenuTree: (params) => get('/permission/getMenuTree', params),
  getPermissionTop: (params) => get('/permission/getStairMenuList', params),
  queryTreeList: (params) => get('/permission/queryTreeList', params),
  getSystemSubmenu: (params) => get('/permission/getSystemSubmenu', params)
}

export default permission
