import { render, staticRenderFns } from "./permitssionTop-model.vue?vue&type=template&id=9f0a2f6a&scoped=true"
import script from "./permitssionTop-model.vue?vue&type=script&lang=js"
export * from "./permitssionTop-model.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f0a2f6a",
  null
  
)

export default component.exports