<template>
  <a-drawer
    :title="title"
    :width="drawerWidth"
    @close="handleCancel"
    :visible="visible"
    :confirmLoading="confirmLoading"
  >
    <div :style="{width: '100%',border: '1px solid #e9e9e9',padding: '10px 16px',background: '#fff',}">
      <a-spin :spinning="confirmLoading">
        <a-form :form="form">

          <a-form-item
            label="菜单类型"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-radio-group
              @change="onChangeMenuType"
              v-decorator="['menuType',{'initialValue':localMenuType}]"
              :disabled="XqDisabled"
            >
              <a-radio :value="0">一级菜单</a-radio>
              <a-radio :value="1">子菜单</a-radio>
              <a-radio :value="2">按钮/权限</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            :label="menuLabel"
            hasFeedback
          >
            <a-input
              placeholder="请输入菜单名称"
              v-decorator="[ 'name', validatorRules.name]"
              :readOnly="disableSubmit"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="localMenuType!=0"
            label="上级菜单"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            :validate-status="validateStatus"
            :hasFeedback="true"
            :required="true"
          >
            <span slot="help">{{ validateStatus=='error'?'请选择上级菜单':'&nbsp;&nbsp;' }}</span>
            <a-tree-select
              style="width:100%"
              :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
              :treeData="treeData"
              v-model="model.parentId"
              :load-data="onLoadData"
              placeholder="请选择父级菜单"
              :disabled="disableSubmit"
              @change="handleParentIdChange"
            >
            </a-tree-select>
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="菜单路径"
          >
            <a-input
              placeholder="请输入菜单路径"
              v-decorator="[ 'url',validatorRules.url]"
              :readOnly="disableSubmit"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="前端组件"
          >
            <a-input
              placeholder="请输入前端组件"
              v-decorator="[ 'component',validatorRules.component]"
              :readOnly="disableSubmit"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="localMenuType==0"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="默认跳转地址"
          >
            <a-input
              placeholder="请输入路由参数 redirect"
              v-decorator="[ 'redirect',{}]"
              :readOnly="disableSubmit"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="!show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="授权标识"
          >
            <a-input
              placeholder="如: user-list"
              v-decorator="[ 'perms', {}]"
              :readOnly="disableSubmit"
            />
          </a-form-item>

          <a-form-item
            v-if="!show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="授权策略"
          >
            <a-select
              v-decorator="[ 'permsType', {}]"
              placeholder="请选择授权策略"
            >
              <a-select-option
                v-for="(item,key) in permsGroup"
                :key="key"
                :value="item.value"
              >{{ item.label}}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="菜单图标"
          >
            <a-input
              placeholder="点击右侧按钮选择图标"
              v-model="model.icon"
              :readOnly="disableSubmit"
              :disabled="XqDisabled"
            >
              <a-icon
                v-if="XqDisabled!=true"
                slot="addonAfter"
                type="setting"
                @click="selectIcons"
              />
            </a-input>
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="排序"
          >
            <a-input-number
              placeholder="请输入菜单排序"
              style="width: 200px"
              v-decorator="[ 'sortNo',validatorRules.sortNo]"
              :readOnly="disableSubmit"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="是否路由菜单"
          >
            <a-switch
              checkedChildren="是"
              unCheckedChildren="否"
              v-model="routeSwitch"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="隐藏路由"
          >
            <a-switch
              checkedChildren="是"
              unCheckedChildren="否"
              v-model="menuHidden"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="是否缓存路由"
          >
            <a-switch
              checkedChildren="是"
              unCheckedChildren="否"
             v-model="isKeepalive"
              :disabled="XqDisabled"
            />
          </a-form-item>

          <a-form-item
            v-if="show"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="聚合路由"
          >
            <a-switch
              checkedChildren="是"
              unCheckedChildren="否"
              v-model="alwaysShow"
              :disabled="XqDisabled"
            />
          </a-form-item>

        </a-form>

        <!-- 选择图标 -->
        <icons
          @choose="handleIconChoose"
          @close="handleIconCancel"
          :iconChooseVisible="iconChooseVisible"
        ></icons>
      </a-spin>
      <a-row :style="{ position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9',
          padding: '10px 16px', background: '#fff', textAlign: 'right' }">
        <a-button
          :style="{marginRight: '8px'}"
          @click="handleCancel"
        >
          关闭
        </a-button>
        <a-button
          :disabled="disableSubmit"
          v-show="showSubmit"
          @click="handleOk"
          type="primary"
        >确定</a-button>
      </a-row>
    </div>
  </a-drawer>
</template>

<script>
import api from './api'
import Icons from '@/components/icon/Icons'
import pick from 'lodash.pick'

export default {
  name: 'PermissionModal',
  components: { Icons },
  data () {
    return {
      XqDisabled: true,
      permsGroup: [
        { label: '启用', value: 0 },
        { label: '禁用', value: 1 }
      ],
      statusGroup: [
        { label: 0, value: 0 },
        { label: 1, value: 1 }
      ],
      drawerWidth: 700,
      treeData: [],
      treeValue: '0-0-4',
      title: '操作',
      visible: false,
      disableSubmit: false,
      showSubmit: false,
      model: {},
      localMenuType: 0,
      alwaysShow: 0, // 表单元素-聚合路由
      menuHidden: 0, // 表单元素-隐藏路由
      routeSwitch: 1, // 是否路由菜单
      isKeepalive: 1, // 是否缓存路由
      show: true, // 根据菜单类型，动态显示隐藏表单元素
      menuLabel: '菜单名称',
      isRequrie: true, // 是否需要验证
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      confirmLoading: false,
      form: this.$form.createForm(this),

      iconChooseVisible: false,
      validateStatus: ''
    }
  },
  computed: {
    validatorRules: function () {
      return {
        name: { rules: [{ required: true, message: '请输入菜单标题!' }] },
        component: { rules: [{ required: this.show, message: '请输入前端组件!' }] },
        url: { rules: [{ required: this.show, message: '请输入菜单路径!' }] },
        permsType: { rules: [{ required: true, message: '请输入授权策略!' }] },
        sortNo: { initialValue: 1.0, rules: [{ validator: this.validateNumber }] }
      }
    }
  },
  created () {
    this.initDictConfig()
  },
  methods: {
    // 获取授权策略以及状态
    getpermsGroup () {
    },
    loadTree () {
      var that = this
      api.queryTreeList().then((res) => {
        that.treeData = []
        const treeList = res.data.treeList
        for (let a = 0; a < treeList.length; a++) {
          const temp = treeList[a]
          temp.isLeaf = temp.leaf
          that.treeData.push(temp)
        }
      })
    },
    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          api.queryTreeList({ parentId: treeNode.dataRef.key }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.treeData = [...this.treeData]
          resolve()
        }, 300)
      })
    },
    add (tabKey) {
      // 默认值
      this.edit({ permsType: 1, route: 1, hideInMenu: 0, alwaysShow: 0, keepAlive: 1, menuType: 0 })
    },
    edit (record) {
      this.resetScreenSize() // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
      this.form.resetFields()
      this.model = Object.assign({}, record)
      // --------------------------------------------------------------------------------------------------
      this.alwaysShow = !!record.alwaysShow
      this.menuHidden = !!Number(record.hidden)
      if (record.route != null) {
        this.routeSwitch = !!record.route
      }

      if (record.keepAlive != null) {
        this.isKeepalive = !!record.keepAlive
      } else {
        this.isKeepalive = false // 升级兼容 如果没有（后台没有传过来、或者是新建）默认为false
      }

      this.show = record.menuType !== 2
      this.menuLabel = record.menuType === 2 ? '按钮/权限' : '菜单名称'

      if (this.model.parentId && this.model.parentId !== 0) {
        this.localMenuType = 1
      } else {
        this.localMenuType = 0
      }
      // ----------------------------------------------------------------------------------------------

      this.visible = true
      this.loadTree()
      const fieldsVal = pick(this.model, 'menuType', 'name', 'url', 'component', 'redirect', 'perms', 'sortNo')
      this.$nextTick(() => {
        this.form.setFieldsValue(fieldsVal)
      })
    },
    close () {
      this.$emit('close')
      this.disableSubmit = false
      this.visible = false
    },
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          this.model.hidden = this.menuHidden ? 1 : 0
          const formData = Object.assign(this.model, values, { keepAlive: this.isKeepalive })
          if ((formData.menuType === 1 || formData.menuType === 2) && !formData.parentId) {
            that.validateStatus = 'error'
            that.$message.error('请检查你填的类型以及信息是否正确！')
            return
          } else {
            that.validateStatus = 'success'
          }
          that.confirmLoading = true
          let obj
          if (!this.model.id) {
            obj = api.addPermission(formData)
          } else {
            obj = api.editPermission(formData)
          }
          obj.then((res) => {
            that.$message.success(res.message)
            that.$emit('ok')
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
        }
      })
    },
    handleCancel () {
      this.close()
    },
    validateNumber (rule, value, callback) {
      if (value && !new RegExp(/^[0-9]*[1-9][0-9]*$/).test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正整数!')
      } else {
        callback()
      }
    },
    onChangeMenuType (e) {
      this.localMenuType = e.target.value
      if (e.target.value === 2) {
        this.show = false
        this.menuLabel = '按钮/权限'
      } else {
        this.show = true
        this.menuLabel = '菜单名称'
      }
      this.$nextTick(() => {
        this.form.validateFields(['url', 'component'], { force: true })
      })
    },
    selectIcons () {
      this.iconChooseVisible = true
    },
    handleIconCancel () {
      this.iconChooseVisible = false
    },
    handleIconChoose (value) {
      this.model.icon = value
      this.form.icon = value
      this.iconChooseVisible = false
    },
    // 根据屏幕变化,设置抽屉尺寸
    resetScreenSize () {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.drawerWidth = screenWidth
      } else {
        this.drawerWidth = 700
      }
    },
    initDictConfig () {
    },
    handleParentIdChange (value) {
      if (!value) {
        this.validateStatus = 'error'
      } else {
        this.validateStatus = 'success'
      }
    }
  }
}
</script>

<style scoped>
</style>
